<template>
  <b-row class="pr-1 pb-0">
    <b-col cols="5" class="mr-0 d-flex flex-column">
      <b-row class="flex-grow-1 pb-0">
        <b-col cols="6" class="border-col align-items-center d-flex">
          <div class="my-auto align-items-center d-flex capitalized-text">
            {{ student.student_grade.student_name }}
          </div>
        </b-col>
        <b-col cols="6" class="border-col">
          <status-table-button :test="student.material_link" />
          <!--show score as percentage  -->
          <div v-if="student.material_link.reviewed" class="score-percentage">
            {{ score }}% de Logro
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="7" class="ml-0 pl-4 d-flex flex-column">
      <b-row class="flex-grow-1 pb-1">
        <b-col cols="4" class="text-center p-0">
          <div
            v-if="student.material_link.started && achieved_tags.length"
            class="mt-2 px-1"
          >
            <div
              v-for="(oa, index) in achieved_tags"
              :key="index"
              variant="info"
              class="text-wrap badge-tag achieved-tag my-1"
            >
              {{ oa.summary }}
            </div>
          </div>
          <div v-else class="mt-2 px-1">
            <div class="badge-tag achieved-tag">-</div>
          </div>
        </b-col>
        <b-col cols="4" class="text-center p-0">
          <div
            v-if="
              student.material_link.started && moderate_achieved_tags.length
            "
            class="mt-2 px-1"
          >
            <div
              v-for="(oa, index) in moderate_achieved_tags"
              :key="index"
              variant="primary"
              class="badge-tag moderate-achieved-tag my-1 text-wrap"
            >
              {{ oa.summary }}
            </div>
          </div>
          <div v-else class="mt-2 px-1">
            <div class="badge-tag moderate-achieved-tag">-</div>
          </div>
        </b-col>
        <b-col cols="4" class="text-center p-0">
          <div
            v-if="student.material_link.started && unachieved_tags.length"
            class="mt-2 px-1"
          >
            <div
              v-for="(oa, index) in unachieved_tags"
              :key="index"
              variant="danger"
              class="text-wrap badge-tag unachieved-tag my-1"
            >
              {{ oa.summary }}
            </div>
          </div>
          <div v-else class="mt-2 px-2">
            <div class="badge-tag unachieved-tag">-</div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import customMaterialService from "@/services/customMaterialService";
// import materialDocumentSocket from "@/mixins/materialDocumentSocket";
import StatusTableButton from "./StatusTableButton.vue";

export default {
  name: "StudentResponseRow",
  props: {
    student: {
      type: Object,
      required: true,
    },
    level: {
      type: Object,
      required: true,
      default: function () {
        return { code: 0, label: "Pre-kinder" };
      },
    },
    grade: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: "A",
          students: [
            {
              id: "8IWX4ER6",
              student_grade: {
                id: "2AQ77TAB",
                student_name: "Estudiante de prueba",
              },
              material_link: {
                id: "0O3T3CPP",
                grade_name: "Pre-kinder A",
                test_id: "B7T2XCXE",
                test_name: "mm",
                student_rut: "18.169.644-3",
                student_email: null,
                student_name: "Estudiante de prueba",
                student_grade: {
                  id: "2AQ77TAB",
                  student_name: "Estudiante de prueba",
                  student_rut: "18.169.644-3",
                  student_email: null,
                  grade_name: "Pre-kinder A",
                  grade_level: 0,
                  grade: "GDFONLA5",
                },
                videocall: "259c40e77a5c5d15c9da19955ddfeb02",
                started: true,
                reviewed: true,
                finished: true,
                needs_video_call: false,
                is_active: true,
              },
              questions_score: {
                ommited_questions: ["REKJB453", "X9TS6O93"],
                score_tags: [
                  {
                    summary: "OA10 IND: Identificar propósitos explícitos",
                    score: 0,
                    total_score: 2,
                  },
                  {
                    summary: "OA10 IND: Argumentar propósitos implícitos",
                    score: 0,
                    total_score: 1,
                  },
                  {
                    summary: "OA04 IND: Información explícita textos",
                    score: 1,
                    total_score: 1,
                  },
                  {
                    summary:
                      "OA03 Analizar narraciones considerando conflictos, personajes, tema, narrador y prejuicios.",
                    score: 0,
                    total_score: 1,
                  },
                  {
                    summary: "OA03 IND: Relevancia fragmento obra",
                    score: 0,
                    total_score: 2,
                  },
                  {
                    summary: "OA03 IND: Resumen hechos orden",
                    score: 0,
                    total_score: 1,
                  },
                ],
                ommited: 2,
                answered: 8,
                score: 1,
                percent_score: 10,
              },
            },
          ],
        };
      },
    },
  },
  components: {
    StatusTableButton,
  },
  // mixins: [materialDocumentSocket],
  mounted() {
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data);
      console.log(message);
      if (message.type === "application_state_updated") {
        if (message.material_response.id == this.student.id) {
          this.student.material_link = message.material_response.material_link;
          this.student.questions_score =
            message.material_response.questions_score;
          console.log(message);
        }
      }
    };
  },
  computed: {
    achieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter((tag) => tag.score / tag.total_score >= 0.6);
    },
    unachieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter((tag) => tag.score / tag.total_score < 0.3);
    },
    moderate_achieved_tags() {
      let tags = this.student.questions_score.score_tags;

      return tags.filter(
        (tag) =>
          tag.score / tag.total_score >= 0.3 &&
          tag.score / tag.total_score < 0.6
      );
    },
    score() {
      return this.student.questions_score.score;
    },
  },
  methods: {
    getValueClasses(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return [
            "text-primary",
            "text-ultralight-hover",
            "bg-primary-hover",
            "border-primary",
          ];
        } else if (materialLink.finished && !materialLink.reviewed) {
          return [
            "text-secondary",
            "text-ultralight-hover",
            "bg-secondary-hover",
            "border-secondary",
          ];
        } else {
          return [
            "text-tertiary",
            "text-ultralight-hover",
            "bg-tertiary-hover",
            "border-tertiary",
          ];
        }
      } else {
        return [
          "text-redpie",
          "text-ultralight-hover",
          "bg-redpie-hover",
          "border-redpie",
        ];
      }
    },
    getTitle(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "NO TERMINADO";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "NO REVISADO";
        } else {
          return "REVISADO";
        }
      } else {
        return "SIN COMENZAR";
      }
    },
    getValueIcon(materialLink) {
      if (materialLink.started) {
        if (!materialLink.finished) {
          return "minus";
        } else if (materialLink.finished && !materialLink.reviewed) {
          return "check";
        } else {
          return "folder-open";
        }
      } else {
        return "times";
      }
    },
    copyLink(materialLink) {
      let testURL = `${process.env["VUE_APP_BASE_URL"]}/materials#/t/${materialLink.id}`;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(testURL);
      } else if (window.clipboardData) {
        window.clipboardData.setData("url", testURL);
      } else {
        this.$toasted.error("No se pudo copiar");
        return;
      }
      this.$toasted.success("Link copiado en el portapapeles");
    },
    closeLink(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          this.$toasted.success(data.message);
        });
    },
    closeAndReview(materialLink) {
      customMaterialService
        .closeMaterial({ invite: materialLink.id })
        .then((data) => {
          materialLink.finished = true;
          this.$toasted.success(data.message);
          this.review(materialLink);
        });
    },
    review(materialLink) {
      this.$emit("response-admin", materialLink, this.level, this.grade);
    },
  },
};
</script>

<style>
.border-col {
  border-right: 1px dashed #e84855;
}

.badge-tag {
  border: 2px solid;
  border-radius: 8px;
  font-size: 0.8em;
}

.unachieved-tag {
  background-color: #efacc8;
  border-color: #e76475;
}

.achieved-tag {
  background-color: #a7d8d8;
  border-color: #49c0bc;
}

.moderate-achieved-tag {
  background-color: #f4d79d;
  border-color: #f2c33a;
}

.capitalized-text::first-letter {
  text-transform: uppercase;
}

.score-percentage {
  color: #17b2ad;
  font-weight: bold;
}
</style>
