<template>
	<div class="custom-selector" :tabindex="tabindex" @blur="open = false" :class="{ 'selector-disabled': disabled }">
		<div class="selected" :class="{ open: open }" @click="open = !open">
			{{ selected.name || placeHolder }}
		</div>
		<div class="items" :class="{ selectHide: !open }">
			<div
				v-for="(option, i) of options"
				:key="i"
				@click="
					if (!disabled) {
						selected = option;
						open = false;
						$emit('input', option);
					}
				"
			>
				{{ option.name }}
			</div>
		</div>
	</div>    
</template>
<script>
export default {
		name: 'SelectorComponent',
		props: {
			placeHolder: {
				type: String,
				required: true,
				default: 'Selecciona una opción'
			},
			tabindex: {
				type: Number,
				required: false,
				default: 0
			},
			options: {
				type: Array,
				required: true
			},
			default: {
				type: Object || null ,
				required: false,
				default: null
			},
			disabled: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				arrow: require('@/assets/img/botpie/arrow-down-selector.svg'),
				selected: this.default
				? this.default
				: this.options.length > 0
				? this.options[0]
				: null,
				open: false,
							
				}
			},
			mounted() {
				this.$emit('input', this.selected)
			},
			watch: {
				value() {
						this.selected = this.value
				}
		},
		methods: {
				change(event) {
					this.$emit('input', event.target.value)
				}
		}

}
</script>
<style scoped>

.custom-selector {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 30px;
  line-height: 30px;
}

.custom-selector .selected {
  border-radius: 6px;
  border: 1.5px solid #E84855;
  color: #000000;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 15.19 15.18'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:none;clip-rule:evenodd;}.cls-2{fill:%23E84855;}.cls-3{clip-path:url(%23clip-path);}%3C/style%3E%3CclipPath id='clip-path'%3E%3Cpolyline class='cls-1' points='-591.95 -196.67 -591.95 -505.81 -139.6 -505.81 -139.6 -196.67'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='FONDO'%3E%3Cpolygon class='cls-2' points='-0.01 3.92 15.22 3.85 7.51 13.78 -0.01 3.92'/%3E%3C/g%3E%3C/svg%3E");
	background-position: right 0.75rem center;
	background-size: 8px 10px;
	background-repeat: no-repeat;
}

.custom-selector .selected.open {
  border: 1.5px solid #E84855;
  border-radius: 6px 6px 0px 0px;
}

.custom-selector .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-selector .items {
  color: #c43e3e;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border: 1.5px solid #E84855;
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  right: 0;
	margin-top: 2px;
  z-index: 1;
}

.custom-selector .items div {
  color: #000000;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-selector .items div:hover {
  background-color: #E48692;
}

.selectHide {
  display: none;
}

.selector-disabled {
	background-color: #f9f9f9 !important;
	color: #c43e3e !important;
	cursor: not-allowed !important;
}
</style>