<template>
  <div class="">
    <info-banner
      :backgroundImg="eurekaBanner"
      :backgroundImgCell="eurekaCell"
      orientation="left"
      title="Evaluaciones
              psicopedagógicas digitales"
      text="Se corrigen automáticamente,
              entregan un informe psicopedagógico completo. "
      btnVariant="outline-primary"
      txtColor="white"
      :tryFunc="() => handleTry('eureka')"
      :learnMore="() => redirect('eureka')"
    />
    <info-banner
      :backgroundImg="planificationsBanner"
      :backgroundImgCell="planificationsCell"
      orientation="right"
      title="¡Planificaciones de clase personalizadas para ti!"
      text="Se crean con inteligencia artificial en segundos y son únicas. En formato diario o mensual, diversificadas y con 
            Taxonomía de Bloom"
      btnVariant="outline-secondary"
      txtColor="white"
      :tryFunc="() => handleTry('planification')"
      :learnMore="() => redirect('planification')"
    />
    <info-banner
      :backgroundImg="pieBanner"
      :backgroundImgCell="pieCell"
      orientation="left"
      title="Plataforma de gestión del Programa de Integración Escolar"
      text="Un sistema completo para trabajar la documentación exigida en el decreto 170, 
            corregir los test automáticamente, aplicar pruebas informales y mucho más."
      btnVariant="outline-purple"
      txtColor="white"
      :learnMore="() => redirect('redpie')"
    />
  </div>
</template>

<script>
import InfoBanner from "@/components/utopie/miscellaneous/InfoBanner";

export default {
  components: { InfoBanner },
  computed: {
    token() {
      return this.$store.state.login.token;
    },
  },
  created() {},
  mounted() {
    if (this.token) {
      this.$connect();
    }
  },
  props: {
    bannerLoaded: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      eurekaBanner: require("@/assets/img/landing_page/PNG/web/Eureka.png"),
      planificationsBanner: require("@/assets/img/landing_page/PNG/web/Planificaciones.png"),
      pieBanner: require("@/assets/img/landing_page/PNG/web/PIE.png"),
      eurekaCell: require("@/assets/img/landing_page/PNG/cell/Eureka.png"),
      planificationsCell: require("@/assets/img/landing_page/PNG/cell/Planificaciones.png"),
      pieCell: require("@/assets/img/landing_page/PNG/cell/PIE.png"),
    };
  },
  destroyed: function () {},
  methods: {
    redirect(service) {
      this.$emit("learn-more", service);
    },
    handleTry(option) {
      this.$emit("try", option);
    },
  },
  watch: {
    token() {
      if (this.token) {
        this.$connect();
      } else {
        this.$disconnect();
      }
    },
  },
};
</script>

<style lang="scss">
.default-container {
  margin-right: 20px !important;
  margin-left: 20px !important;
  padding-top: 85px !important;
}
.big-padding {
  padding-left: 17rem !important;
  padding-right: 17rem !important;
}
.medium-padding {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
body {
  font-family: Ubuntu, "times new roman", times, roman, serif !important;
}
</style>
