<template>
  <div
    :class="
      $router.currentRoute.name !== 'planification'
        ? 'general-container'
        : 'global-container'
    "
  >
    <!-- v-if="$store.state.login.token">-->
    <loading-image-modal ref="loading-modal" />
    <TutorialModal ref="tutorial-modal" />
    <FeedbackButtonComponent ref="feedback-modal" />
    <MakeTestModal ref="make-test-modal" />
    <RedpieTutorialModal ref="redpie-tutorial-modal" />
    <SetCountryModal ref="country-modal" v-on:get-posts="force_get_posts" />
    <b-modal ref="message-modal" hide-footer hide-header no-close-on-backdrop>
      <b-container>
        <b-row>
          {{ message }}
        </b-row>
        <b-row>
          <b-img :src="saveAsImg" fluid />
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button variant="primary" pill @click="closeAndUpdate">
              Entendido!
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- <SidebarComponent class="d-none d-xl-inline d-lg-inline d-md-inline">
      <TutorialComponent v-on:get-filtered-posts="get_posts_by_subtag"/>
    </SidebarComponent> -->
    <HeaderComponent
      style="z-index: 999"
      v-on:search-by-text="search_by_text"
      v-on:set-not-new-notifications="set_not_new_notifications"
      v-on:load-notifications="get_my_previous_notifications"
      @set-open-login-fun="setOpenLogin"
      @hidden="checkAndChange"
    />
    <div style="" class="mx-0 header-margin">
      <info-banner
        :backgroundImg="mainBanner"
        :backgroundImgCell="mainCell"
        orientation="center"
        title="Utopie, el software más avanzado al servicio de la educación"
        text="Tú trabajas con los niños, nosotros hacemos el resto."
        txtColor="black"
      />
      <b-container
        ref="buttons"
        class="p-0"
        fluid
        style="overflow-x: hidden"
        id="buttons"
      >
        <b-row class="d-none d-md-flex">
          <b-col
            v-for="(option, oIndex) in options"
            :key="oIndex + '-option'"
            class="text-center"
            :class="
              option.mode === 'redpie' && !$store.state.login.isPaid ? '' : ''
            "
          >
            <div class="text-center d-block mt-4">
              <b-img
                :src="option.icon"
                v-if="
                  $router.currentRoute.name !== option.mode &&
                  service !== option.mode
                "
                class="menu-button"
                :style="'width: ' + option.width"
                @click="changeView(option.mode)"
              />
              <div v-else>
                <b-button
                  variant="primary"
                  pill
                  @click="
                    service = '';
                    $router.push('home');
                    service = '';
                  "
                  class="d-inline d-md-none"
                >
                  <v-icon
                    name="arrow-alt-circle-left"
                    scale="4"
                    class="my-2 mx-1"
                  />
                </b-button>
                <b-button
                  variant="primary"
                  pill
                  @click="
                    service = '';
                    $router.push('home');
                    service = '';
                  "
                  class="d-none d-md-inline"
                >
                  <v-icon
                    name="arrow-alt-circle-left"
                    scale="4"
                    class="my-2 mx-1"
                  />
                </b-button>
              </div>
            </div>
            <strong class="d-none d-md-block mt-3" style="font-size: 2vw">
              <p
                v-html="
                  $router.currentRoute.name !== option.mode &&
                  service !== option.mode
                    ? option.name
                    : 'Volver'
                "
              />
            </strong>
          </b-col>
        </b-row>
        <b-row class="d-flex d-md-none">
          <b-col
            v-for="(option, oIndex) in options"
            :key="oIndex + '-option'"
            class="text-center"
            :class="
              (option.hideOnCell ? 'd-none' : '') +
              (option.mode === 'redpie' && !$store.state.login.isPaid ? '' : '')
            "
            :cols="option.hideOnCell ? 0 : ''"
          >
            <div class="text-center d-block mt-4">
              <b-img
                :src="option.icon"
                v-if="
                  $router.currentRoute.name !== option.mode &&
                  service !== option.mode
                "
                class="menu-button"
                :style="'width: ' + option.widthCell"
                @click="changeView(option.mode)"
              />
              <b-button
                variant="primary"
                pill
                v-else
                @click="
                  service = '';
                  $router.push('home');
                  service = '';
                "
              >
                <v-icon
                  name="arrow-alt-circle-left"
                  scale="1.3"
                  class="my-2 mx-1"
                />
              </b-button>
            </div>
            <strong class="d-block d-md-none mt-3" style="font-size: 15px">
              <p
                v-html="
                  $router.currentRoute.name !== option.mode &&
                  service !== option.mode
                    ? option.name
                    : 'Volver'
                "
              />
            </strong>
          </b-col>
        </b-row>
      </b-container>
      <transition name="slide-fade" mode="out-in">
        <router-view
          ref="main"
          :tags="tags"
          :bannerLoaded="bannerLoaded"
          :key="reload"
          :routeName="service"
          class="main-component"
          @view-file="view_file"
          @points-change="reload_points"
          @create-school="createSchool"
          @create-school-docs="createSchoolDocs"
          @open-test-modal="$refs['make-test-modal'].open()"
          @toggle-reload="reload = !reload"
          @try="changeView"
          @learn-more="goToServices"
          @open-login="openLoginTrial"
        />
      </transition>
      <contact-us id="contact" ref="contact" :route="service"></contact-us>
    </div>
    <b-modal id="bv-modal-view-file" size="xl" hide-footer>
      <template v-slot:modal-title>
        <b-spinner
          variant="success"
          type="grow"
          label="Spinning"
          v-if="rate_loading"
        />
        <star-rating
          v-model="bound_rating"
          @rating-selected="rating_selected"
          v-else
        />
      </template>
      <iframe :src="view_link" class="w-100" style="height: 66vh" />
      <a :href="download_link">
        <b-button variant="info" class="mt-1" @click="downloaded" block
          >Descargar</b-button
        >
      </a>
    </b-modal>
    <!-- <BottomButton class="d-block d-md-none" /> -->
  </div>
</template>

<script>
import authService from "../../services/authService";
import FeedbackButtonComponent from "../../components/utopie/modals/FeedbackButtonComponent";
import HeaderComponent from "../../components/utopie/miscellaneous/HeaderComponent";
import TagsAndSubtags from "../../components/utopie/miscellaneous/TagsAndSubtags";
import Banner from "../../components/utopie/miscellaneous/Banner";
import notificationService from "../../services/notificationService";
import missionService from "../../services/missionService";
import fileService from "../../services/fileService";
import postService from "../../services/postService";
import userService from "../../services/userService";
import ProfileSidebarComponent from "../../components/utopie/miscellaneous/ProfileSidebarComponent";
import RightSidebarComponent from "../../components/utopie/miscellaneous/RightSidebarComponent";
import SidebarComponent from "../../components/utopie/miscellaneous/SidebarComponent";
import TagComponent from "../../components/utopie/miscellaneous/TagComponent";
import TutorialComponent from "../../components/utopie/miscellaneous/TutorialComponent";
import TagsAndSubtags2 from "../../components/utopie/imported/TagsAndSubtags";
import TutorialModal from "../../components/utopie/modals/TutorialModal";
import SiteLoginComponent from "../../components/utopie/miscellaneous/SiteLoginComponent";
import SetCountryModal from "../../components/utopie/modals/SetCountryModal";
import MakeTestModal from "../../components/utopie/modals/MakeTestModal";
import BottomButton from "../../components/utopie/miscellaneous/BottomButton";
import RedpieTutorialModal from "../../components/redpie/modals/TutorialModal";
// import redpieService from "../../services/redpieService";
import LoadingImageModal from "../../components/utopie/modals/LoadingImageModal";
import ContactUs from "@/components/sales/miscellaneous/ContactUs";
import InfoBanner from "@/components/utopie/miscellaneous/InfoBanner";

export default {
  components: {
    HeaderComponent,
    FeedbackButtonComponent,
    TutorialComponent,
    SidebarComponent,
    ProfileSidebarComponent,
    TagComponent,
    TagsAndSubtags,
    RightSidebarComponent,
    TagsAndSubtags2,
    SiteLoginComponent,
    TutorialModal,
    BottomButton,
    SetCountryModal,
    RedpieTutorialModal,
    MakeTestModal,
    Banner,
    LoadingImageModal,
    ContactUs,
    InfoBanner,
  },
  computed: {
    headerHeight() {
      let element = document.querySelector("#header");
      if (element) {
        let height = element.offsetHeight;
        return height;
      }
      return 0;
    },
    token() {
      return this.$store.state.login.token;
    },
  },
  created() {
    const vue_scope = this;
    userService.get_user_data().then((data) => {
      this.$store.commit("login/setUser", data, { root: true });
      if (data.message) {
        this.message = data.message;
        this.$refs["message-modal"].show();
      }
    });
    if (this.$store.state.login.token) {
      this.verify_token();
      this.$connect();
      userService.get_user_data().then((data) => {
        this.$store.commit("login/setUser", data, { root: true });
        this.check_country();
        if (data.message) {
          this.message = data.message;
          this.$refs["message-modal"].show();
        }
      });
    } else {
      // const current_date = new Date().valueOf().toString();
      // const random = Math.random().toString();
      // this.$store.commit(
      //   "login/setAnonID",
      //   (current_date + random).replace(".", ""),
      //   { root: true }
      // );
      // this.$connect(process.env.VUE_APP_SOCKET_URL + `/socket/${this.$store.state.login.anonID}`);
    }
    this.get_new_notifications();
    this.get_my_notifications();
    this.getUncompletedMissions();
    this.get_tags();
    this.$options.sockets.onmessage = (payload) => {
      let data = JSON.parse(payload.data).message;
      if (!data) {
        data = JSON.parse(payload.data);
      }

      if (data.kind === "notification") {
        vue_scope.$toasted.success(data.text).goAway(1500);
        this.get_new_notifications();
        this.get_my_last_notifications();
      }
      if (data.kind === "mission_completed") {
        this.$store.state.login.missions.filter(
          (m) => m.mission.mission_code === data.mission
        )[0].completed = true;
        userService.get_user_data().then((data) => {
          this.$store.commit("login/setUser", data, { root: true });
        });
      }
      if (data.type === "school_created") {
        this.$store.commit("login/setSchool", data.school, { root: true });
        if (this.redirect_to_tests) {
          this.redirect_to_tests = false;
          window.location.href = "/redpie#/tutorial-tests";
        } else if (this.redirect_to_docs) {
          this.redirect_to_docs = false;
          window.location.href = "/redpie#/tutorial-docs";
        } else {
          window.location.href = "/redpie#/tutorial";
        }
      }
    };
    // eslint-disable-next-line
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.view_link = "";
      this.download_link = "";
    });
    if (process.browser) {
      window.addEventListener("resize", this.set_main_container_position, {
        passive: true,
      });
      this.set_main_container_position();

      this.$nextTick(() => {
        this.set_main_container_position();
      });
    }
  },
  data: function () {
    return {
      reload: false,
      bannerLoaded: false,
      mode: "landing",
      mainBanner: require("@/assets/img/landing_page/PNG/web/Principal.png"),
      mainCell: require("@/assets/img/landing_page/PNG/cell/Principal.png"),
      eurekaBanner: require("@/assets/img/landing_page/PNG/Landing Page_Banner sin botón_Eureka@4x.png"),
      planificationsBanner: require("@/assets/img/landing_page/PNG/Landing Page_Banner sin botón_Planificaciones@4x.png"),
      pieBanner: require("@/assets/img/landing_page/PNG/Landing Page_Banner sin botón_Mi Colegio@4x.png"),
      materialsBanner: require("@/assets/img/landing_page/PNG/Landing Page_Banner sin botón_Materiales@4x.png"),
      options: [
        {
          name: "Eureka",
          icon: require("@/assets/img/landing_page/Iconos/Ícono_Eureka copia@4x.png"),
          mode: "eureka",
          redirect: "/comprar#/eureka",
          width: "100px",
          widthCell: "70px",
        },
        {
          name: "Planificación<br/>de clase",
          icon: require("@/assets/img/landing_page/Iconos/Ícono_planif clases copia@4x.png"),
          mode: "planification",
          redirect: "/comprar#/planification",
          width: "100px",
          widthCell: "70px",
        },
        // {
        //   name: "Materiales<br/>Interactivos",
        //   icon: require("@/assets/img/landing_page/Iconos/Ícono_materiales interactivos copia@4x.png"),
        //   mode: "materials",
        //   redirect: "/comprar#/materials",
        //   width: "50px",
        //   widthCell: "50px",
        // },
        {
          name: "Red Social",
          icon: require("@/assets/img/landing_page/Iconos/Ícono_red social copia@4x.png"),
          mode: "social",
          width: "100px",
          widthCell: "70px",
          hideOnCell: true,
        },
        // {
        //   name: "Precios",
        //   icon: require("@/assets/img/landing_page/Iconos/Ícono_comprar copia@4x.png"),
        //   mode: "prices",
        //   width: "5vw",
        // },
        {
          name: "Plataforma PIE",
          icon: require("@/assets/img/landing_page/Iconos/Landing Page_íconoÍcono_plataforma pie niño inside.svg"),
          mode: "redpie",
          redirect: "/comprar#/",
          width: "100px",
          widthCell: "73px",
        },
      ],
      text: "",
      view_link: "",
      download_link: "",
      bound_rating: 0,
      id: "",
      tags: [],
      rate_loading: false,
      ProfileKey: 0,
      last_notification: null,
      older_notification: null,
      openLogin: null,
      windowWidth: 0,
      redirect_to_tests: false,
      redirect_to_docs: false,
      service: "",
      message: "",
      saveAsImg: require("../../assets/img/save_as.jpg"),
    };
  },
  destroyed: function () {
    if (process.browser) {
      window.removeEventListener("resize", this.set_main_container_position);
    }
  },
  methods: {
    checkAndChange() {
      if (this.$store.state.login.token && this.service) {
        this.changeView(this.service);
        this.service = "";
      }
    },
    goToServices(service) {
      this.service = service;
      this.$router.push("services-details");
      var objDiv = document.getElementById("buttons");
      objDiv.scrollIntoView();
    },
    changeView(option) {
      var objDiv = document.getElementById("buttons");
      if (
        this.$store.state.login.token ||
        option === "social" ||
        option === "prices"
      ) {
        if (option === "redpie") {
          window.location.href = "/redpie#/";
        } else if (option === "eureka" && this.$store.state.login.isPaid) {
          window.location.href = "/redpie#/eureka";
        } else if (option === "prices") {
          console.log(option);
        } else {
          objDiv.scrollIntoView();
          if (this.$router.currentRoute.name !== option) {
            this.$router.push(option);
          }
        }
      } else {
        this.$toasted.error("Inicia sesión para utilizar este servicio!");
        this.goToServices(option);
      }
    },
    closeAndUpdate() {
      userService.alertMessageSeen();
      this.$refs["message-modal"].hide();
    },
    downloaded() {
      fileService.materialDownload();
    },
    setPadding() {
      if (this.windowWidth > 1800) {
        return "big-padding";
      } else if (this.windowWidth > 1500) {
        return "medium-padding";
      }
    },
    setOpenLogin(openFun) {
      this.openLogin = openFun;
    },
    openLoginTrial() {
      if (!this.$store.state.login.token) {
        this.$toasted.error("Inicia sesión para realizar una prueba gratuita!");
        this.openLogin(true);
      } else {
        this.changeView(this.service);
        this.service = "";
      }
    },
    openRedpieTutorial: function () {
      this.$refs["redpie-tutorial-modal"].open();
    },
    openTutorial: function () {
      this.$refs["tutorial-modal"].open();
    },
    openFeedback: function () {
      this.$refs["feedback-modal"].open();
    },
    openOka() {
      this.$refs["modal-add-grade"].open();
    },
    reload_points: function () {
      userService.get_user_data().then((data) => {
        this.$store.commit("login/setPoints", data, { root: true });
      });
    },
    getUncompletedMissions: function () {
      missionService.getUncompletedMissions().then((data) => {
        this.$store.commit("login/setMissions", data);
      });
    },
    get_posts: function () {
      this.$refs.main.get_posts();
    },
    force_get_posts: function () {
      this.$refs.main.force_get_posts();
    },
    get_files: function () {
      this.$refs.main.reset();
    },
    get_posts_by_tag: function (tag) {
      this.$refs.main.get_posts_by_tag(tag);
    },
    get_posts_by_subtag: function (subtag) {
      if (this.$router.currentRoute.name === "home")
        this.$refs.main.get_posts_by_subtag(subtag);
      else {
        this.$store.commit(
          "login/setSubtag",
          { subtag: subtag },
          { root: true }
        );
        this.$router.push({ name: "home" });
      }
    },
    get_my_notifications: function () {
      notificationService.get_my_notifications().then((data) => {
        const notifications = data.notifications;
        if (notifications.length > 0) {
          this.last_notification = notifications[0];
          this.older_notification = notifications[notifications.length - 1];
        }
        this.$store.commit("login/setNotifications", notifications, {
          root: true,
        });
      });
    },
    get_my_last_notifications: function () {
      notificationService
        .get_my_notifications({
          last: this.last_notification.created_at,
        })
        .then((data) => {
          const notifications = data.notifications;
          if (notifications.length > 0) {
            this.last_notification = notifications[0];
          }
          this.$store.commit("login/addNotification", notifications, {
            root: true,
          });
        });
    },
    get_my_previous_notifications: function () {
      notificationService
        .get_my_notifications({
          previous: this.older_notification.created_at,
        })
        .then((data) => {
          const notifications = data.notifications;
          if (notifications.length > 0) {
            this.older_notification = notifications[notifications.length - 1];
          }
          this.$store.commit("login/pushNotification", notifications, {
            root: true,
          });
        });
    },
    get_new_notifications: function () {
      notificationService.get_new_notifications().then((data) => {
        this.$store.commit(
          "login/setNewNotifications",
          data.new_notifications_count,
          { root: true }
        );
      });
    },
    set_not_new_notifications: function () {
      notificationService.set_not_new_notifications().then((data) => {
        this.$store.commit(
          "login/setNewNotifications",
          data.new_notifications_count,
          { root: true }
        );
      });
    },
    verify_token: function () {
      authService.verify_token().then(
        // eslint-disable-next-line
        (data) => {
          // console.log("verifying token...");
        },
        // eslint-disable-next-line
        (error) => {
          this.$toasted.error("Tu sesión ha expirado.");
          this.$store.commit("login/setUserEmpty", { root: true });
        }
      );
    },
    set_main_container_position() {
      this.windowWidth = window.innerWidth;
      const header = document.querySelector("#header");
      const mainContainer = document.querySelector("#mainContainer");

      if (header && mainContainer) {
        mainContainer.style.paddingTop = `${header.offsetHeight}px`;
      }
    },
    search_by_text: function (text) {
      this.$refs.main.search(text);
    },
    get_tags: function () {
      postService.get_tags().then(
        (data) => {
          this.tags = data.tags;
        },
        (error) => {
          console.error(error);
        }
      );
    },
    view_file: function (links) {
      this.rate_loading = true;
      this.download_link = links.download_link;
      this.view_link = links.view_link;
      this.$bvModal.show("bv-modal-view-file");
      this.id = links.id;
      postService.get_my_file_rate({ id: this.id }).then(
        (data) => {
          this.bound_rating = data.rate;
          this.rate_loading = false;
        },
        // eslint-disable-next-line
        (error) => {
          this.$toasted.error("Ha ocurrido un error obteniendo tu voto.");
          this.bound_rating = 0;
          this.rate_loading = false;
        }
      );
    },
    close_login_dropdown: function () {
      this.$refs.loginDropdown.hide();
    },
    rating_selected: function () {
      postService
        .rate_file({ bound_rating: this.bound_rating, id: this.id })
        .then(
          (data) => {
            this.$toasted.success(data.message);
          },
          // eslint-disable-next-line
          (error) => {
            this.$toasted.error(
              "Ha ocurrido un error guardando tu voto, inténtalo más tarde."
            );
            this.bound_rating = 0;
          }
        );
    },
    createSchool: function () {
      if (
        this.$store.state.login.token === "" ||
        (!this.$store.state.login.school && this.$store.state.login.token)
      ) {
        // var payload = {
        //   socket: (this.$store.state.login.token === ""? this.$store.state.login.anonID : this.$store.state.login.user_id)
        // }
        // this.redirect_to_tests = true;
        // redpieService.createSchool(payload).then(
        //   () => {
        //     this.$refs['loading-modal'].open(true, false, false)
        //   }
        // )
        // console.log("no tiene colegio");
        this.$toasted.error(
          "Necesitas tener un colegio asociado para usar esta funcionalidad!"
        );
      } else {
        if (this.$store.state.login.isPaid) {
          window.location.href = "/redpie#/tutorial";
        } else {
          this.$toasted.error(
            "Necesitas tener acceso a Redpie para usar esta funcionalidad!"
          );
        }
      }
    },
    createSchoolDocs: function () {
      if (
        this.$store.state.login.token === "" ||
        (!this.$store.state.login.school && this.$store.state.login.token)
      ) {
        // var payload = {
        //   socket: (this.$store.state.login.token === ""? this.$store.state.login.anonID : this.$store.state.login.user_id)
        // }
        // this.redirect_to_docs = true;
        // redpieService.createSchool(payload).then(
        //   () => {
        //     this.$refs['loading-modal'].open(true, false, false)
        //   }
        // )
        // console.log("no tiene colegio");
        this.$toasted.error(
          "Necesitas tener un colegio asociado para usar esta funcionalidad!"
        );
      } else {
        if (this.$store.state.login.isPaid) {
          window.location.href = "/redpie#/tutorial-docs";
        } else {
          this.$toasted.error(
            "Necesitas tener acceso a Redpie para usar esta funcionalidad!"
          );
        }
      }
    },
    check_country: function () {
      // if (this.$store.state.login.country < 1) {
      //   this.$nextTick(
      //     () => setTimeout(
      //       () => this.$refs["country-modal"].open(),
      //       2000
      //     )
      //   );
      // }
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.$store.state.login.token) {
        this.verify_token();
      }
    },
    // eslint-disable-next-line
    token(new_value, old_value) {
      if (new_value) {
        this.check_country();
        this.get_my_notifications();
        this.$connect(
          process.env.VUE_APP_SOCKET_URL +
            "/socket/" +
            this.$store.state.login.token
        );
      } else {
        // const current_date = new Date().valueOf().toString();
        // const random = Math.random().toString();
        // this.$store.commit("login/setAnonID", current_date + random, {
        //   root: true,
        // });
        // this.$connect(
        //   process.env.VUE_APP_SOCKET_URL +
        //     `/socket/${this.$store.state.login.anonID}`
        // );
      }
      this.get_tags();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors";
@import "../../assets/scss/main";
@import "../../assets/scss/variables";

.header-margin {
  margin-top: $headerHeight;
}

.main-component {
  min-height: 100vh;
  background-color: #ffffff !important;
}
.general-container {
  background-color: #ffffff !important;
}
.default-container {
  margin-right: 20px !important;
  margin-left: 20px !important;
  padding-top: 85px !important;
}
.big-padding {
  padding-left: 17rem !important;
  padding-right: 17rem !important;
}
.medium-padding {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
body {
  font-family: Ubuntu, "times new roman", times, roman, serif !important;
}
.menu-button {
  cursor: pointer;
  transition: transform 0.2s;
}
.menu-button:hover {
  transform: scale(1.1);
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
</style>
