<template>
	<div class="w-100">
		<div v-if="failed && text.content_type == 1" class="text-center">
			<b-alert show variant="danger">
				No se pudo generar el texto, por favor intenta de nuevo
			</b-alert>
			<!-- add a buttom to remount component -->
			<b-button @click="generateHistory" variant="danger">Reintentar</b-button>
		</div>
		<div v-if="text.content_type === 0" class="custom-editable-text">
			<div> 
				<!-- quiero que sean inputs, el titulo sea un input y el content sea un text area -->
				<b-form-input v-model="text.title" placeholder="Título" class="my-1" />
				<b-form-textarea rows="20" v-model="text.content" placeholder="Contenido" class="my-1" ref="textarea" />
			</div>
		</div>
		<b-row v-if="text.content_type === 1 && loading && !failed">
			<b-col class="text-center">
				<b-progress 
					:max="loadingMax" 
					animated 
					height="2rem"
					variant="danger"
				>
				<b-progress-bar
					:value="loadingValue" 
					>
						{{ Math.floor((loadingValue/loadingMax) * 100) }}%
					</b-progress-bar>
				</b-progress>
			</b-col>
		</b-row>
		<div v-if="text.content_type === 1 && !loading">
			<CustomAudioSlider :content="text.story_chapters" />
		</div>
	</div>
</template>
<script>
import assistedMaterialService from '@/services/assistedMaterialService'
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";

export default {
	name: 'TextMessageComponent',
	props: {
		context: {
			type: Object,
			required: true
		}
	},
	components: {
		CustomAudioSlider
	},
	data() {
		return {
			text: {
				content_type: 0,
				title: '',
				content: '',
			},
			loading: true,
			loadingMax: 90,
			loadingValue: 0,
			failed: false
		}
	},
	mounted() {
		this.$store.commit('chatbot/updateMessage', {'name': 'validate_text', 'key': 'createdText', 'value': this.text})
		const charactersOptions = this.context["characters_options"]["name"]
		const plotOptions = this.context["plot_options"]["name"] 
		const textTopic = this.context["text_topic"]["value"]
		const nPerson = this.context["final_settings"][0]["selected"]["name"]
		const type = 3
		const details = this.context["text_topic_details"]["textInput"]
		const age = this.context["grade"]["code"] + 4
		const textStyle = this.context["final_settings"][1]["selected"]["name"]
		const textType = this.context["final_settings"][2]["selected"]["name"]
		this.text.content_type = textType === "Solo texto" ? 0 : 1
		this.text.title = this.context["plot_options"]["title"]
		const interval = setInterval(() => {
					this.loadingValue += 1;
					if (this.loadingValue === this.loadingMax) {
						clearInterval(interval);
					}
				}, 1000);
		const objectContext = this;
		assistedMaterialService.generateText(
			charactersOptions, 
			plotOptions, 
			textTopic, 
			nPerson, 
			type, 
			details, 
			age,
			textStyle
		).then((response) => {
			const reader = response.getReader()
			const decoder = new TextDecoder()
			function processChunk({ done, value}){
				if (value) {
					const decoded = decoder.decode(value)
					const matrix = []

					if (decoded.includes(';')) {
						let array = decoded.split(';').map( (item) => {
							let stringWithoutBrackets = item.slice(1,-1)
							return stringWithoutBrackets
						})
						for (const text of array) {
							matrix.push(text)
						}
					} else {
						let stringWithoutBrackets = decoded.slice(1, -1)
						matrix.push(stringWithoutBrackets)
					}
					for (const text of matrix) {
						objectContext.text.content += text
						if (objectContext.text.content_type === 0) {
							
							objectContext.$nextTick(() => {
								objectContext.$refs.textarea.$el.scrollTop = objectContext.$refs.textarea.$el.scrollHeight
							})
						}
					}
				}
				if (done) {
					if (objectContext.text.content_type == 1 ){
						
						assistedMaterialService.generateHistory({
							title: objectContext.text.title,
							text: objectContext.text.content,
							school: objectContext.$store.state.login.school,
						}).then((response) => {
							objectContext.text = response
							objectContext.$store.commit('chatbot/updateMessage', {'name': 'validate_text', 'key': 'createdText', 'value': response})
	
							objectContext.loading = false
							console.log("data", response)
						}).catch(error => {
							objectContext.failed = true;
							console.log(error)
						})
					}
					return
				}
				return reader.read().then(processChunk)
			}
			return reader.read().then(processChunk)
		}).catch(error => {
			objectContext.failed = true;
			console.log(error)
		})

	},
	methods: {
		generateHistory() {
			this.loading = true
			this.failed = false
			this.text = {
				content_type: 1,
				title: '',
				content: '',
			}
			this.loadingValue = 0
			this.mounted()
		}
	}
	
}
</script>
<style>
.custom-editable-text {
	background-color: #ffffff;
	border-radius: 15px;
	border: 1.5px solid #E84855;
	padding: 1em 1.5em;
}
</style>