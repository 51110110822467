<template>
  <div>
    <!-- <div
						v-for="(msg, msgidx) in messages"
						:key="msgidx"
						class="chat-record mt-2"
					> -->
    <b-container class="box-question rounded text-right">
      <b-row class="box-list-question my-2">
        <b-col>
          <p v-html="text" class="rounded"></p>
          <p
            v-if="hint && showHint"
            class="question-hint"
            :ref="`msg-hint-${name}`"
            v-html="'Indicación: ' + hint"
          ></p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row
        v-if="
          optionalCheckbox !== null &&
          typeof optionalCheckbox !== 'undefined' &&
          showAnswers
        "
      >
        <b-col>
          <b-form-checkbox
            v-model="optionalCheckbox"
            name="optionalCheckbox"
            class="optional-checkbox"
          >
            {{ optionalCheckboxText }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row
        v-if="answersLoaded && showAnswers"
        :class="{
          'options-type': type === 'options',
          'cards-type': type === 'cards',
          'options-counter-type': type === 'options-counter',
          'hybrid-options-type': type === 'hybrid-options',
          'selector-options-type': type === 'selector-options',
          'multiple-text-type': type === 'multiple-text',
          'text-area-type': type === 'text-area',
          'custom-component-type': type === 'custom-component',
        }"
      >
        <b-col
          class="col-4"
          v-for="(answer, ansidx) in answers"
          :key="ansidx"
          :class="answer.class"
        >
          <div v-if="type === 'cards'">
            <b-card
              class="m-2 option-card text-center font-weight-bold w-100"
              :class="{
                'selected-card': answer.isSelected,
              }"
              @click="
                disabled
                  ? $emit('select-card', answer, msgidx, ansidx, max)
                  : null
              "
              :header="answer.title"
            >
              <b-card-text
                :class="answer.bootstrap_classes"
                class="font-weight-normal"
              >
                {{ answer.name }}
              </b-card-text>
            </b-card>
          </div>

          <div v-if="type === 'options'" class="w-100">
            <b-card
              class="text-center option-option format-text p-1"
              :class="{ 'selected-option': answer.isSelected }"
              @click="
                disabled
                  ? $emit('select-card', answer, msgidx, ansidx, max)
                  : null
              "
              v-if="
                answer.isSelected ||
                !singleChoice ||
                (singleChoice &&
                  answers.filter((ans) => ans.isSelected).length < 1)
              "
            >
              <div
                :class="{
                  'd-flex': answer.price || answer.priority,
                  'align-items-center': !answer.price && !answer.priority,
                  'justify-content-between': answer.price || answer.priority,
                }"
              >
                <div
                  v-if="answer.title"
                  v-html="answer.title"
                  class="font-weight-bold"
                ></div>
                <div
                  v-html="answer.name"
                  :class="answer.bootstrap_classes"
                ></div>

                <div
                  v-if="answer.priority == 1"
                  class="d-flex align-items-center m-1"
                >
                  <v-icon name="custom-star" scale="1.5" />
                </div>
              </div>
            </b-card>
          </div>
          <div v-if="type === 'options-counter'">
            <b-row class="py-2 mb-1">
              <p v-html="answer.name_section"></p>
            </b-row>
            <b-row
              class="py-2 mb-1"
              v-if="
                answer.has_warning && !(answer[answer.key] >= answer.required)
              "
            >
              <b-alert show variant="warning" class="w-100">{{
                answer.warning_text
              }}</b-alert>
            </b-row>
            <b-row
              v-for="(opt, optidx) in answer.options"
              :key="optidx"
              class="py-2 my-1"
            >
              <b-col cols="3" class="counter-container col-sm-2 my-auto mr-3">
                <v-icon
                  name="minus"
                  class="counter-button"
                  @click="disabled ? $emit('decrease', opt, answer) : null"
                  scale="0.8"
                />
                <b-input
                  type="number"
                  class="no-arrow input-no-border input-counter"
                  disabled
                  v-model="opt.count"
                />
                <v-icon
                  name="plus"
                  class="counter-button"
                  @click="disabled ? $emit('increase', opt, answer) : null"
                  scale="0.8"
                />
              </b-col>
              <b-col
                :class="{ 'selected-option': opt.count > 0 || opt.isSelected }"
                class="my-auto counter-text-container"
              >
                <p v-html="opt.name" class="my-1"></p>
              </b-col>
            </b-row>
          </div>
          <div v-if="type === 'hybrid-options'">
            <b-row class="mb-1">
              <b-col
                v-if="!optionalCheckbox"
                cols="3"
                class="counter-container col-sm-2 my-auto mr-5"
              >
                <v-icon
                  name="minus"
                  class="counter-button"
                  scale="1"
                  @click="disabled ? handleDecreaseHybrid(answer) : null"
                />
                <b-input
                  type="number"
                  class="no-arrow input-no-border input-counter"
                  v-model="answer.count"
                  disabled
                />
                <v-icon
                  name="plus"
                  class="counter-button"
                  scale="1"
                  @click="disabled ? handleIncreaseHybrid(answer) : null"
                />
              </b-col>

              <b-col
                :class="{
                  'selected-option': answer.count > 0 || answer.isSelected,
                }"
                class="my-auto counter-text-container"
                @click="
                  () => {
                    !optionalCheckbox
                      ? handleIncreaseHybrid(answer)
                      : $emit('select-card', answer, msgidx, ansidx, max);
                  }
                "
              >
                <b-row>
                  <b-col>
                    <p v-html="answer.name" class="my-auto"></p>
                  </b-col>
                  <b-col
                    v-if="answer.priority == 1"
                    cols="2"
                    md="1"
                    class="my-auto mr-3"
                  >
                    <v-icon name="custom-star" scale="1.5" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div v-if="type === 'selector-options'">
            <selector-component
              :options="
                answer.options.map((opt) => {
                  return {
                    name: opt,
                  };
                })
              "
              :default="answer.selected"
              @input="
                (option) => $emit('selector-change', msgidx, ansidx, option)
              "
            />
          </div>
          <div v-if="type === 'multiple-text'">
            <b-form-input
              class="text-input-answer mb-1"
              :type="answer.type"
              v-model="answer.value"
              :placeholder="answer.label"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="type === 'text-area'" class="box-answer mb-2">
      <b-form-textarea
        class="w-100 text-area-answer"
        :rows="3"
        placeholder="Inserta tu respuesta aquí ..."
        v-model="textInput"
      ></b-form-textarea>
    </div>
    <!-- si está cargado entonces poner un cargando -->
    <ul v-if="!showAnswers && !answersLoaded" class="w-100">
      <b-row>
        <b-col class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </b-col>
      </b-row>
    </ul>
    <div
      v-if="type === 'custom-component' && showAnswers"
      class="box-answer mb-2"
    >
      <component :is="component" :context="context" />
    </div>
    <b-container>
      <b-row
        v-if="!singleChoice && answersLoaded && !isFinal && showAnswers"
        class="continue-button py-2"
        :class="answered ? 'continue-off' : 'continue-on'"
        @click="handleContinue()"
      >
        <b-col> {{ nextMessage ? nextMessage : "¡Listo! Siguiente" }} </b-col>
        <b-col cols="2" class="col-sm-1">
          <v-icon
            :name="
              answered
                ? 'continue-arrow-white'
                : 'continue-arrow-color-materials'
            "
          />
        </b-col>
      </b-row>
    </b-container>
    <!-- </div> -->
  </div>
</template>
<script>
import SelectorComponent from "./util-components/SelectorComponent.vue";
import CreateMaterial from "@/components/botpie-assistant/CreateMaterial";
import ApplicationComponent from "@/components/botpie-assistant/ApplicationComponent";
import TextMessageComponent from "./TextMessageComponent.vue";
// import materialDocumentSocket from "@/mixins/materialDocumentSocket";

export default {
  props: {
    initMsg: {
      type: Object,
      required: true,
    },
    msgidx: {
      type: Number,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    SelectorComponent,
    CreateMaterial,
    ApplicationComponent,
    TextMessageComponent,
  },
  name: "ChatMessageComponent",
  // mixins: [materialDocumentSocket],
  data() {
    return {
      text: "",
      hint: "",
      name: "",
      textInput: "",
      answered: false,
      answers: [],
      showHint: false,
      answersLoaded: false,
      showAnswers: false,
      type: "options",
      singleChoice: true,
      isFinal: false,
      max: 1,
      optionalCheckbox: null,
      optionalCheckboxText: "Opcional",
      disabled: true,
      component: null,
      nextMessage: "",
      loadingValue: 1,
      loadingMax: 100,
      loadingMessage: "Cargando...",
    };
  },
  watch: {
    "initMsg.answers": {
      handler: function (val) {
        this.answers = val;
      },
      deep: true,
    },
    optionalCheckbox: function (value) {
      // que recorra answers y setee count con 0
      if (value !== null && value !== undefined) {
        this.answers.forEach((answer) => {
          answer.isSelected = false;
          answer.count = 0;
        });
        this.$emit("select-checkbox", this.msgidx, value);
      }
    },
    textInput: function (value) {
      this.$emit("set-text-input", this.msgidx, value);
    },
  },
  mounted() {
    this.$store.commit("chatbot/addMessage", this.initMsg);
    this.name = this.initMsg.name;
    this.showHint = this.initMsg.showHint;
    this.isFinal = this.initMsg.isFinal;
    this.answersLoaded = this.initMsg.answersLoaded;
    this.nextMessage = this.initMsg.nextMessage
      ? this.initMsg.nextMessage
      : "¡Listo! Siguiente";
    this.singleChoice = this.initMsg.single_choice;
    this.type = this.initMsg.type;
    this.max = this.initMsg.max;
    this.optionalCheckboxText = this.initMsg.optionalCheckboxText;
    this.optionalCheckbox = this.initMsg.optionalCheckbox;
    this.component = this.initMsg.component ? this.initMsg.component : null;

    if (this.isFinal) {
      // Eliminar el event listener antes de agregarlo nuevamente
      const container = document.querySelector(".global-container");
      container.removeEventListener(
        "DOMNodeInserted",
        this.$emit("scroll-to-bottom")
      );
      container.addEventListener(
        "DOMNodeInserted",
        this.$emit("scroll-to-bottom")
      );
    }

    if (this.initMsg.getAnswers) {
      this.initMsg.getAnswers(this.context).then((answers) => {
        this.answers = answers;
        this.answersLoaded = true;
        this.setText()
          .then(() => {
            if (this.initMsg.hint) {
              this.showHint = true;
              this.setHint().then(() => {
                this.showAnswers = true;
                this.$forceUpdate();
                this.$nextTick(() => {
                  this.$emit("scroll-to-msg", this.name);
                });
              });
            } else {
              this.showAnswers = true;
              this.$forceUpdate();
              this.$nextTick(() => {
                this.$emit("scroll-to-msg", this.name);
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
    } else {
      this.setText()
        .then(() => {
          if (this.initMsg.hint) {
            this.showHint = true;
            this.setHint().then(() => {
              this.showAnswers = true;
              this.$forceUpdate();
              this.$nextTick(() => {
                this.$emit("scroll-to-msg", this.name);
              });
            });
          } else {
            this.showAnswers = true;
            this.$forceUpdate();
            this.$nextTick(() => {
              this.$emit("scroll-to-msg", this.name);
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  },
  methods: {
    setText() {
      return new Promise((resolve) => {
        let content =
          typeof this.initMsg.text === "function"
            ? this.initMsg.text()
            : this.initMsg.text;
        let i = 0;
        let timer = setInterval(() => {
          if (i < content.length && content.charAt(i)) {
            this.text += content.charAt(i);
            i++;
            this.$forceUpdate();
          } else {
            clearInterval(timer);
            resolve();
          }
        }, 10);
      });
    },
    setHint() {
      return new Promise((resolve) => {
        // resolve content
        let content =
          typeof this.initMsg.hint === "function"
            ? this.initMsg.hint()
            : this.initMsg.hint;
        // type effect
        let i = 0;
        // targetObj[keyTarget] = "";
        let timer = setInterval(() => {
          if (i < content.length && content.charAt(i)) {
            this.hint += content.charAt(i);
            i++;
            this.$forceUpdate();
          } else {
            clearInterval(timer);
            resolve();
          }
        }, 10);
      });
    },

    handleDecreaseHybrid(answer) {
      if (answer.count > 0) {
        answer.count--;
      }
    },
    handleIncreaseHybrid(answer) {
      answer.count++;
    },
    handleContinue() {
      this.$emit("continue");
      this.answered = true;
    },
  },
};
</script>
<style scoped lang="scss">
.chat-box {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
}
.box-list-answer {
  display: flex;
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.chat-box-container {
  margin-bottom: 1px;
}

.box-list-span {
  padding: 8px;
  color: white;
  border-radius: 4px;
}
.box-list-question {
  // float: right;
  background-color: #f9f9f9;
  color: rgb(0, 0, 0);
  border: 1pt dashed #e84855;
  border-radius: 15px;
  font-family: "Ubuntu";
  max-width: 80%;
}
.box-list-question p {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.box-list-answer {
  float: left;
  /* background-color: white; */
}
.box-question {
  display: flex;
  justify-content: flex-end;
}
.box-question,
.box-answer {
  width: 100%;
  height: auto;
}

.question-hint {
  color: rgb(0, 0, 0, 0.5);
}
.chat-inputs {
  /* display: flex; */
  float: right;
}
.chat-send {
  float: right;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
/* CARD OPTIONS */
.selected-card {
  background-color: rgb(0, 185, 174) !important;
  color: rgb(0, 0, 0) !important;
}
.option-card {
  border: 1.5pt solid #e84855 !important;
  cursor: pointer;
  max-height: 30rem;
  border-radius: 5px !important;
  font-family: "Ubuntu", Arial, sans-serif;
  color: #000000;
}
.option-option {
  max-height: 30rem;
  color: #000000;
  cursor: pointer;
  border: 1.5pt solid #e84855 !important;
  border-radius: 5px !important;
  font-family: "Ubuntu";
  .card-body {
    padding: 0 !important;
  }
}

/* LITTLE CARD OPTIONS */
.selected-option {
  background-color: #e48692 !important;
  border: 1.5pt solid #e84855 !important;
  color: #ffffff !important;
}

/* CONTINUE BUTTON */
.continue-button {
  border-radius: 10px;
  border: 2pt solid #e84855 !important;
  font-family: "Ubuntu";
  font-weight: bold; /* Aplica negrita al texto */
}
.continue-on {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  color: #e84855;
}
.continue-off {
  background-color: #e48692;
  cursor: not-allowed;
  color: #ffffff;
}

/* COUNTER OPTIONS*/

.counter-section {
  float: left;
  background-color: rgb(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  border: 1pt dashed #e84855;
  border-radius: 10px;
  font-family: "Ubuntu Medium", Arial, sans-serif;
}

.counter-option {
  float: left;
  background-color: rgb(255, 255, 255, 0.5);
  color: rgb(0, 0, 0);
  border: 1pt dashed #e84855;
  border-radius: 10px;
  font-family: "Ubuntu Medium", Arial, sans-serif;
  font-weight: bold; /* Aplica negrita al texto */
}

input.no-arrow::-webkit-inner-spin-button,
input.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-no-border {
  border: none !important;
  outline: none !important;
}
.input-counter {
  text-align: center;
  font-weight: bold;
}
.counter-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1.5pt solid #e84855;
  border-radius: 5px;
  height: min-content;
  font-size: 0.5rem;
}
.counter-button {
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

input[type="number"]:disabled {
  background: #ffffff;
}
.counter-text-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: white;
  border: 1.5pt solid #e84855;
  border-radius: 5px;
  cursor: pointer;
}

.text-area-answer {
  width: 100%;
  height: 100%;
  border: 1.5pt solid #e84855;
  resize: none;
}

// .options-type, .cards-type{
// }

.options-counter-type,
.hybrid-options-type {
  // width: 100%;
}

.text-input-answer {
  width: 100%;
  height: 100%;
  border: 1.5pt solid #e84855;
  resize: none;
}

.text-input-answer .form-control:focus {
  border-color: #e84855 !important;
}

.format-text {
  white-space: pre-wrap;
}
</style>

<style lang="scss">
.optional-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #e84855 !important;
    background-color: #e48692 !important;
  }
  .optional-checkbox.custom-control-input:checked ~ .custom-control-label {
    font-weight: bold !important;
  }
}
</style>
